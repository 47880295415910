import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss'
import 'amfe-flexible' //

import { initNativeBridge } from './utils/nativeBridge';
import { nativeBridgeConfig } from './utils/nativeBridge';
import { Locale } from 'vant';
import filters from './utils/filters'
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);
// import '@vant/touch-emulator';


import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/vant.css'
import touch from 'vue-directive-touch';
Vue.use(touch);

import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
//

//
import { getRandomAlphaNum } from '@/utils/config.js'
Vue.prototype.getRandomAlphaNum = getRandomAlphaNum
import { preventReClick } from '@/utils/directive.js'
Vue.directive('preventReClick', preventReClick)
import { customerActionLog } from './api/utils.js';
Vue.prototype.$customerActionLog = customerActionLog;
initNativeBridge();
window.nativeBridgeConfig = nativeBridgeConfig;



// import Vconsole from 'vconsole';
// let vconsole = new Vconsole;
// vconsole
// Vue.prototype.$api = api
import '@/styles/index.scss'
import '@/icons' // icon
Vue.use(Vant);
Vue.config.productionTip = false
//
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});



// 设置全局 CSS 变量


// import * as fundebug from "fundebug-javascript";
// import FundebugVue from "fundebug-vue";
// fundebug.apikey = "9e3696edf201948e4206c432f0d999a2a292a6151e027290485a81e05d1b10fd"
// new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x
// console.log(document.documentElement.style.fontSize, '$$$$$$$$$$$$$11111');
// window.addEventListener('resize', setRemUnit);
// window.addEventListener('orientationchange', setRemUnit);

function setRemUnit() {
  var clientWidth = document.documentElement.clientWidth;

  if (clientWidth > 1000) {
    clientWidth = 375;
  }

  var remSize = clientWidth / 10;
  document.documentElement.style.fontSize = remSize + 'px';

}
window.pushKey = function (iosKey) {
  console.log(iosKey, '111111111111111111111*********')
  localStorage.setItem('iosKey', iosKey)
}

async function fetchAndSetBaseInfo() {
  try {
    await store.dispatch("fetchFlutterBaseInfo");
    const baseInfo = JSON.parse(localStorage.getItem('BaseInfo'));
    console.log(baseInfo, '******************1111111111111')
    if (baseInfo && baseInfo.mColor) {
      const mColor = baseInfo.mColor;
      console.log(mColor, baseInfo, '**********mColor')
      document.documentElement.style.setProperty('--m-color', mColor);
    } else {
      document.documentElement.style.setProperty('--m-color', '#1989FA');
    }
  } catch (error) {
    console.error('Failed to fetch baseInfo:', error);
    document.documentElement.style.setProperty('--m-color', '#1989FA');
  }
}

if (localStorage.getItem('iosKey')) {
  fetchAndSetBaseInfo();
} else if (typeof window.android !== 'undefined') {
  fetchAndSetBaseInfo();
} else {
  document.documentElement.style.setProperty('--m-color', '#1989FA');
}

setRemUnit();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
